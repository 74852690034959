import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { createSelectors } from '~utils/createSelectors'

export type FiltersStore = {
  filters: Filters
  setFilters: (filters: Filters) => void
  clearAllFilters: () => void
  onUpdateFilter: (filters: Partial<Filters>) => void
  onToggleTag: (tag: string) => void
  clearAllTags: () => void
}

// Enforces filter to be string or undefined instead of optional as all filter params should exist
export type Filters = {
  formats: string[] | undefined
  geolocation: string | undefined
  capturedAt: {
    startDate: string | undefined
    endDate: string | undefined
  }
  tags: string[] | undefined
}

export const defaultFilters = {
  formats: undefined,
  geolocation: undefined,
  capturedAt: { startDate: undefined, endDate: undefined },
  tags: undefined,
}

const FiltersStore = create<FiltersStore>()(
  devtools(
    (set, get) => ({
      filters: defaultFilters,
      setFilters: (filters: Filters) =>
        set({
          filters,
        }),
      clearAllFilters: () => set({ filters: defaultFilters }),
      onUpdateFilter: (values: Partial<Filters>) => {
        set((state) => ({ filters: { ...state.filters, ...values } }))
      },
      onToggleTag: (tag: string) => {
        const tagSet = new Set(get().filters.tags)
        if (tagSet.has(tag)) {
          tagSet.delete(tag)
        } else {
          tagSet.add(tag)
        }
        get().onUpdateFilter({
          tags: tagSet.size > 0 ? Array.from(tagSet) : undefined,
        })
      },
      clearAllTags: () => {
        get().onUpdateFilter({ tags: undefined })
      },
    }),
    { enabled: process.env.NODE_ENV !== 'production' },
  ),
)

export const useGenerateFiltersQuery: () => Filters = () => {
  const filters = useFiltersStore((state) => state.filters)
  return filters
}

export const useAreFiltersApplied: () => boolean = () => {
  const filters = useFiltersStore((state) => state.filters)
  return !(
    filters.tags === undefined &&
    filters.formats === undefined &&
    filters.geolocation === undefined &&
    filters.capturedAt.startDate === undefined &&
    filters.capturedAt.endDate === undefined
  )
}

export const useAreTagFiltersApplied: () => boolean = () => {
  const filters = useFiltersStore((state) => state.filters)
  return filters.tags !== undefined
}

export const useFiltersStore = createSelectors(FiltersStore)

import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const switchHelper = createMultiStyleConfigHelpers(switchAnatomy.keys)

const noActive = switchHelper.definePartsStyle({
  track: {
    bg: 'interaction.sub.default',
    _checked: {
      bg: 'interaction.sub.default',
    },
  },
})

export const switchTheme = switchHelper.defineMultiStyleConfig({
  variants: { noActive },
})

export const globalStyles = () => ({
  /**
   * When  ::-webkit-scrollbar width  is not set, the default browser behavior
   * is to show a scrollbar only when you hover on an overflowing container.
   *
   * When ::-webkit-scrollbar width is set, the scrollbar will always show when the container can overflow.
   *
   * The following replicates the default behavior despite setting the scrollbar width
   */
  '::-webkit-scrollbar': {
    width: '0.375rem',
    display: 'none',
  },
  ':hover::-webkit-scrollbar': {
    display: 'block',
  },

  /**
   * Do not show a scrollbar on body (eg. landing page, login page)
   */
  'body::-webkit-scrollbar, body:hover::-webkit-scrollbar': {
    display: 'none',
  },

  /**
   * Scrollbar styles
   */
  '::-webkit-scrollbar-track': {
    background: 'base.canvas.overlay',
  },
  '::-webkit-scrollbar-thumb': {
    background: 'base.content.medium',
    borderRadius: '4px',
  },

  /* For Firefox */
  scrollbarColor: `var(--chakra-colors-base-canvas-overlay) var(--chakra-colors-base-content-medium)`,
  scrollbarWidth: 'thin',

  /** Flash */
  '@-webkit-keyframes flash': {
    '0%': { borderColor: 'rgba(181, 179, 233, 0.3)' },
    '10%': { borderColor: 'rgba(181, 179, 233, 1)' },
    '25%': { borderColor: 'rgba(181, 179, 233, 0)' },
    '45%': { borderColor: 'rgba(181, 179, 233, 1)' },
    '65%': { borderColor: 'rgba(181, 179, 233, 0)' },
    '80%': { borderColor: 'rgba(181, 179, 233, 1)' },
    '100%': { borderColor: 'rgba(181, 179, 233, 0)' },
  },
})

import { extendTheme } from '@chakra-ui/react'
// Importing from main so @chakra-cli can work properly without complaining about ESM.
import { theme as ogpTheme } from '@opengovsg/design-system-react/'

import { components } from './components'
import { colors } from './generated/colors'
import { shadows } from './generated/shadows'
import { spacing } from './generated/spacing'
import { textStyles } from './generated/textStyles'
import { typography } from './generated/typography'
import { globalStyles } from './globalStyles'

/**
 * Design system themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen/themes.
 * README for importing themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen.
 */
export const theme = extendTheme(ogpTheme, {
  styles: {
    global: globalStyles,
  },
  components,
  colors: { ...colors },
  shadows: shadows,
  space: spacing,
  fontSizes: typography.fontSize,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  letterSpacings: typography.letterSpacing,
  textStyles,
})
